import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './styles.module.scss';

export default function RodoQuestion({ question, children, className }) {
  return (
    <div className={classnames(styles.rodoQuestion, className)}>
      <h2>{question}</h2>
      <div>{children}</div>
    </div>
  );
}

RodoQuestion.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string,
  children: PropTypes.node,
};

RodoQuestion.defaultProps = {
  className: '',
  question: '',
  children: <></>,
};
