import React from 'react';

import RodoQuestion from '../../../components/RodoQuestion';
import * as styles from './styles.module.scss';
import RodoListItem from '../../../components/RodoListItem';

export default function RodoSection() {
  return (
    <section className={styles.rodoSection}>
      <div className={styles.rodoTopBar}>Klauzula RODO dla Pacjentów</div>
      <div className={styles.rodoContentWrapper}>
        <p>Zgodnie z art. 13 ust.1 i ust. 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych – RODO), informuję iż:</p>
        <ol>
          <RodoListItem>
            Administratorem Państwa danych osobowych jest Fizjoterapia Robert Biernacki, prowadzący działalność gospodarczą zarejestrowaną w Tychach 43-100, ul. Rudolfa Zaręby, nr 23, lok. 17, NIP 6462267189, REGON 273934768, zwany dalej „Administratorem”.
          </RodoListItem>

          <RodoListItem>
            Administrator nie powołał inspektora ochrony danych osobowych. W sprawach związanych z przetwarzaniem Pani/Pana danych osobowych, należy kontaktować się z Administratorem osobiście, telefonicznie pod numerem telefonu <a href="tel:0048600989249">600 989 249</a> lub e-mail: na adres <a href="mailto:biernacki.fizjoterapia@gmail.com">biernacki.fizjoterapia@gmail.com</a>.
          </RodoListItem>

          <RodoListItem>
            Administrator przetwarza Pani/Pana dane osobowe w celu objęcia Pani/Pana opieką zdrowotną w ramach wykonywanego świadczenia, zgodnie z art. 6 ust. 1 lit. a, art. 6 ust. 1 lit. c, art. 6 ust. 1 lit. f oraz art. 9 ust. 2 lit. h ogólnego rozporządzenia o ochronie danych – RODO z dnia 27 kwietnia 2016 r.
          </RodoListItem>

          <RodoListItem>
            <p>Odbiorcami Pani/Pana danych osobowych będą:</p>
            <ul>
              <li>podmioty uprawnione do uzyskania danych osobowych na podstawie przepisów prawa,</li>
              <li>podmioty, z którymi Administrator ma podpisane umowy powierzenia przetwarzania danych osobowych oraz świadczących usługi: księgowe, teleinformatyczne oraz prowadzące kontrole, audyty lub monitoring.</li>
            </ul>
          </RodoListItem>
        
          <RodoListItem>
            Pani/Pana dane osobowe przechowywane będą przez okres 20 lat, zgodnie z obowiązującymi aktami prawnymi zobowiązującymi do archiwizowania dokumentacji medycznej.
          </RodoListItem>
          
          <RodoListItem>
            Posiada Pani/Pan prawo dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania, prawo do cofnięcia zgody w dowolnym momencie, zgodnie z obowiązującymi przepisami prawa.
          </RodoListItem>

          <RodoListItem>
            Ma Pani/Pan prawo wniesienia skargi do Organu Nadzorczego, gdy uzna Pani/ Pan iż przetwarzanie danych osobowych Pani/Pana dotyczących, narusza przepisy ogólnego rozporządzenia o ochronie danych
          </RodoListItem>

          <RodoListItem>
            Podanie przez Panią/Pana danych osobowych jest dobrowolne, jednakże niepodanie przez Panią/Pana swoich danych osobowych będzie skutkować brakiem możliwości realizacji określonych zadań przewidzianych przepisami prawa
          </RodoListItem>

          <RodoListItem>
            Pani/Pana dane nie będą poddane zautomatyzowanemu podejmowaniu decyzji (profilowaniu).
          </RodoListItem>
        </ol>
      </div>
    </section>
  );
}
