import '../styles/_global.scss';

import * as React from 'react';

import Seo from '../components/seo';
import RodoSection from '../sections/Rodo/RodoSection';

export default function IndexPage() {
  return (
    <>
      <Seo title="Klauzula RODO" />
      <RodoSection />
    </>
  );
}
