import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './styles.module.scss';

export default function RodoListItem({ children, className }) {
  return (
    <li className={classnames(styles.rodoListItem, className)}>
      {children}
    </li>
  );
}

RodoListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

RodoListItem.defaultProps = {
  className: '',
  children: <></>,
};
